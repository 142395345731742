.ric-hidden {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden !important;
}

.ric-input::-webkit-input-placeholder {
    color: #444;
    font-style: italic;
}
.ric-input::-moz-placeholder {
    color: #444;
    font-style: italic;
}
.ric-input:-ms-input-placeholder {
    color: #444;
    font-style: italic;
}
.ric-input:-moz-placeholder {
    color: #444;
    font-style: italic;
}

.ric-list:empty {
    display: none !important;
}
